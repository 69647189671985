<template>
  <component :is="menuData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="menuData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('error_fetching') }}
      </h4>
      <div class="alert-body">
        {{ $t('menu_not_found') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-menus-list'}"
        >
          {{ $t('menu_list') }}
        </b-link>
        {{ $t('for_other_menu') }}
      </div>
    </b-alert>

    <b-tabs
      v-if="menuData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('buttons.menu') }}</span>
        </template>
        <menu-edit-tab-account
          :menu-data="menuData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import menuStoreModule from '../menuStoreModule'
import MenuEditTabAccount from './menuEditTabAccount'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    MenuEditTabAccount,    
  },
  setup() {
    const menuData = ref(null)

    const WHITELABEL_APP_STORE_MODULE_NAME = 'app-menu'

    // Register module
    if (!store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) store.registerModule(WHITELABEL_APP_STORE_MODULE_NAME, menuStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) store.unregisterModule(WHITELABEL_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-menu/fetchMenu', { id: router.currentRoute.params.id })
      .then(response => { menuData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          menuData.value = undefined
        }
      })

    return {
      menuData,
    }
  },
}
</script>

<style>

</style>
