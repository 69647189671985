<template>
  <div>

    <!-- Menu Info: Input Fields -->
    <b-form>
      <b-row class="align-items-center">
          
        <!-- Field: Menu Title -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('title')"
            label-for="title"
            :state="Boolean(menu.title)"
          >
            <b-form-input
              id="title"
              aria-describedby="input-live-help input-live-feedback"
              v-model="menu.title"
            />
          </b-form-group>
          <b-form-invalid-feedback id="input-live-feedback">
            {{ $t('enter_title')}}
          </b-form-invalid-feedback>
        </b-col>

        <!-- Field: Desplegable -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="font size"
          >
            <b-form-input
              type="number"
              name="check-button"
              v-model="menu.font_size"
              :state="Boolean(menu.font_size)"
              aria-describedby="input-live-help input-live-feedback"
              inline
            >
            </b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              {{ $t('enter_font_size') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- field font size-->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group>
            <b-form-checkbox
              name="check-button"
              v-model="menu.is_desplegable"
              switch
              inline
            >
              {{ $t('labels.dropdown') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <!-- Field: Backgroud Color -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
              :label="$t('labels.backgroundcolor')"
              label-for="backgroundcolor"
            >
              <b-form-input
                type="color"
                id="backgroundcolor"
                v-model="menu.background_color"
                trim
              />
            </b-form-group>
        </b-col>

        <!-- Field: Background Image -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
              :label="$t('labels.backgroundimage')"
              label-for="backgrounimage"
            >
              <b-form-file
                id="backgroundimage"
                v-model="menu.image"
                trim
              />
            </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="!Boolean(menu.font_size) || !Boolean(menu.title) "
      @click="updateMenu(menuData._id)"
    >
      Save Changes
    </b-button>
    
  </div>
</template>

<script>
import {
  BButton, BFormFile, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BFormFile,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  props: {
    menuData: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      menu: {}
    }
  },
  mounted(){
    this.menu = this.menuData
  },
  methods: {
    
    updateMenu(Id) {
      //console.log(this.menuData)
      const form = new FormData()
      let data = {...this.menuData, ...this.menu}
      console.log(data)
      form.append('title', data.title)
      form.append('font_size', data.font_size)
      form.append('is_desplegable', data.is_desplegable)
      form.append('image', data.image)
      form.append('background_color', data.background_color)
      store.dispatch('app-menu/updateMenu', { id: Id, menuData: form})
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('menu_updated'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
